/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Team = () => {
  return (
    <div>
      <section className="team_section layout_padding">
        <div className="container-fluid">
          <div className="heading_container heading_center">
            <h2 className="">
              Our <span style={{ color: "#18034b" }}> Team</span>
            </h2>
          </div>

          <div className="team_container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="box">
                  <div className="img-box">
                    <img src="images/team-1.jpg" className="img1" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>CMA.Vadivel</h5>
                    <p>M.Com, MBA, FCMA, CS</p>
                  </div>
                  <div className="social_box">
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-instagram"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-youtube-play"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="box">
                  <div className="img-box">
                    <img src="images/team-2.jpg" className="img1" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>Employee 1</h5>
                    <p>Marketing Head</p>
                  </div>
                  <div className="social_box">
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-instagram"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-youtube-play"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="box">
                  <div className="img-box">
                    <img src="images/team-3.jpg" className="img1" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>Employee 2</h5>
                    <p>Marketing Head</p>
                  </div>
                  <div className="social_box">
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-instagram"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-youtube-play"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="box">
                  <div className="img-box">
                    <img src="images/team-4.jpg" className="img1" alt="" />
                  </div>
                  <div className="detail-box">
                    <h5>Employee 3</h5>
                    <p>Marketing Head</p>
                  </div>
                  <div className="social_box">
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-instagram"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <a href="#">
                      <i
                        className="fa fa-youtube-play"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
