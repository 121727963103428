import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./clientStyle.css";

// Import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const Client = () => {
  return (
    <>
      <div
        className="heading_container heading_center psudo_white_primary"
        style={{ marginTop: "1rem" }}
      >
        <h2>Gallery</h2>
      </div>
      <Swiper
        slidesPerView={1} // Default to 1 slide for smaller screens
        spaceBetween={20}
        loop={true}
        autoplay={{
          delay: 3000, // Autoplay delay in milliseconds
          disableOnInteraction: false,
        }}
        breakpoints={{
          // Responsive breakpoints
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="images/owner1.jpg" alt="Owner" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="images/books1.jpg" alt="Books" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="images/board.jpg" alt="Board" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="images/entrance.jpg" alt="Entrance" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="images/room.jpg" alt="Room" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="images/owner3.jpg" alt="Owner 2" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Client;
