/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const Info = () => {
  return (
    <div>
      <section className="info_section layout_padding2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 info_col">
              <div className="info_contact">
                <h4>Contact</h4>
                <div className="contact_link_box">
                  <a
                    href="https://www.google.com/maps/place/A.+Vadivel+%26+Co,+H-10,+Sri+Venkateswara+Complex,+Mathur,+Post,+Oragadam,+Sriperumbudur,+Tamil+Nadu+602105/data=!4m2!3m1!1s0x3a52f18a7484d667:0x5869890eccad63e5?utm_source=mstt_1&entry=gps&coh=192189&g_ep=CAESCjExLjE1NS4xMDEYACCIJyp1LDk0MjIzMjk5LDk0MjE2NDEzLDk0MjEyNDk2LDk0MjA3Mzk0LDk0MjA3NTA2LDk0MjA4NTA2LDk0MjE3NTIzLDk0MjE4NjUzLDk0MjI5ODM5LDk0MjM5MTI3LDQ3MDg3MTE4LDQ3MDg0MzkzLDk0MjEzMjAwQgJJTg%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span> Location </span>
                  </a>
                  <a href="tel:+919840623976">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span> Call +91 9840623976/ 9345024787 </span>
                  </a>
                  <a href="mailto:avadivelandco@gmail.com">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span> avadivelandco@gmail.com </span>
                  </a>
                  <a href="mailto:avmvadivel@gmail.com">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span> avmvadivel@gmail.com </span>
                  </a>
                </div>
              </div>
              <div className="info_social">
                <a href="#">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 info_col">
              <div className="info_detail">
                <h4>Address</h4>
                <p>
                  Doo.No.H-10, Sri Venkateshwara Complex, <br />
                  Sriperumbudur to SP Kovil Highway Road,
                  <br />
                  Mathur Post, Oragadam, Sriperumbudur, Chennai - 602 105.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 mx-auto info_col">
              <div className="info_link_box">
                <h4>Links</h4>
                <div className="info_links">
                  <Link className="active" to="/">
                    {" "}
                    Home{" "}
                  </Link>
                  <Link className="" to="/about">
                    {" "}
                    About{" "}
                  </Link>
                  <Link className="" to="/services">
                    {" "}
                    Services{" "}
                  </Link>
                  <Link className="" to="/why-us">
                    {" "}
                    Why Us{" "}
                  </Link>
                  <Link className="" to="/team">
                    {" "}
                    Team{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 info_col">
              <h4>Subscribe</h4>
              <form action="#">
                <input type="text" placeholder="Enter email" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Info;
