import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';
import About from './components/About';
import Client from './components/Client';
import Footer from './components/Footer';
import Header from './components/Header';
import Info from './components/Info';
import Services from './components/Services';
import Slider from './components/Slider';
import Team from './components/Team';
import WhyUs from './components/WhyUs';

// Define routes for reuse
export const routes = [
  { path: '/' },
  { path: '/about' },
  { path: '/services' },
  { path: '/why-us' },
  { path: '/team' },
  { path: '/client' },
  { path: '/info' },
];

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Slider />
              <Services />
              <About />
              <WhyUs />
              <Team />
              <Client />
            </>
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/team" element={<Team />} />
        <Route path="/client" element={<Client />} />
        <Route path="/info" element={<Info />} />
      </Routes>
      <Info />
      <Footer />
    </div>
  );
}

export default App;
